import React, { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Box,
  VStack,
  Textarea,
} from '@chakra-ui/react';

export interface DropdownProps {
  selectedOptions: string[];
  setSelectedOptions: (opts: string[]) => void;
  options: string[];
}

const DropdownWithDecoupledOtherInput = ({ selectedOptions, setSelectedOptions, options }: DropdownProps) => {
  const [otherValue, setOtherValue] = useState('');

  const [showOther, setShowOther] = useState(false);

  const handleOptionClick = (option: string) => {
    if (option === 'Other') {
      setShowOther(true);
      return; // Do nothing for "Other" here, as it has a separate input
    }
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleRemoveOption = (option: string) => {
    setSelectedOptions(selectedOptions.filter((o) => o !== option));
  };

  const handleOtherInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherValue(e.target.value);
  };

  const handleAddOther = () => {
    if (otherValue.trim() && !selectedOptions.includes(otherValue)) {
      setSelectedOptions([...selectedOptions, otherValue]);
      setOtherValue(''); // Clear the input field after adding
      setShowOther(false);
    }
  };

  return (
    <Box w="300px">
      {/* Dropdown Menu */}
      <Menu>
        <MenuButton mb={4} as={Button} variant="outline" w="100%">
          Please select your reasons
        </MenuButton>
        <MenuList>
          {options.map((option) => (
            <MenuItem key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {/* Separate Input for "Other" */}
      {showOther && (
        <VStack mt={2} mb={2} spacing={2} align="stretch">
          <Textarea
            placeholder="Specify other"
            value={otherValue}
            onChange={handleOtherInputChange}
          />
          <Button background="gray.500" color="white" onClick={handleAddOther} colorScheme="blue">
            Add
          </Button>
        </VStack>
      )}

      {/* Selected Tags */}
      <Box mb={2}>
        {selectedOptions.map((option) => (
          <Tag key={option} colorScheme="blue" m={1}>
            <TagLabel>{option}</TagLabel>
            <TagCloseButton onClick={() => handleRemoveOption(option)} />
          </Tag>
        ))}
      </Box>
    </Box>

  );
};

export default DropdownWithDecoupledOtherInput;
