import React from 'react';
import { SlideFade, Text } from '@chakra-ui/react';
import { Subscription } from '../models/customer';
import { getRemainingMonths } from '../utils';
import { Plan } from '../models/plans';

interface DiscountInfoProps {
  plan: Plan
  sub: Subscription
  isFocused: (arg: string) => boolean
  couponIds?: Array<string>
  isModal?: boolean
}

const DiscountInfo: React.FC<DiscountInfoProps> = ({
  plan, sub, isFocused, couponIds = ['WELCOME10', 'WELCOME20'], isModal = false,
}) => {
  let remainingMonths = getRemainingMonths(sub?.created_at || 0);

  const validCoupon = sub.billing_details.coupons.find((coupon) => couponIds.includes(coupon.id));
  if (validCoupon) { remainingMonths = getRemainingMonths(validCoupon.created, validCoupon.duration_in_months); }

  const hasValidCoupon = sub?.billing_details?.coupons
    && remainingMonths > 0
    && sub.billing_details.coupons.some((coupon) => couponIds.includes(coupon.id));

  if (!hasValidCoupon) return null;

  if (isModal) {
    return (
      <Text color="deepSpace.900" mt={4} variant="mini" mb="1rem">
        Additional
        {' '}
        <b>
          {validCoupon?.percent_off}
          %
        </b>
        {' '}
        for
        {' '}
        <b>
          {remainingMonths}
          {' '}
          more months

        </b>
        ,
        {' '}
        then
        {' '}
        <Text as="strong">
          $
          {plan?.cost}
        </Text>
        {' '}
        per month, including GST.
      </Text>
    );
  }

  return (
    <SlideFade
      transition={{ enter: { duration: 0.3 }, exit: { duration: 0.3 } }}
      in={isFocused(plan.neptune_id)}
    >
      <Text color="deepSpace.100" variant="mini" mb="1rem" noOfLines={1}>
        Additional
        {' '}
        {validCoupon?.percent_off}
        %
        {' '}
        for
        {' '}
        {remainingMonths}
        {' '}
        more months, then
        {' '}
        <Text as="strong">
          $
          {plan?.cost}
        </Text>
        {' '}
        per month
      </Text>
    </SlideFade>
  );
};

export default DiscountInfo;
