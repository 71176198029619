import * as ipaddr from 'ipaddr.js';
import CIDR from 'ip-cidr';
import dayjs from 'dayjs';
import { Coupon, Subscription } from './models/customer';

export const neptuneRanges = ['103.252.194.0/23', '2401:2520::/32', '2401:dc20::/32', '160.250.144.0/23'];

export const isNeptuneIp = (clientIp: string): boolean => {
  // Check if it's a valid IP address (IPv4 or IPv6)
  if (!ipaddr.isValid(clientIp)) {
    return false;
  }

  let isNeptune = false;
  neptuneRanges.forEach((ipRange) => {
    const cidr = new CIDR(ipRange);
    // Check if the client IP is within either of the Neptune ranges
    if (cidr.contains(clientIp)) {
      isNeptune = true;
    }
  });
  return isNeptune;
};

export function isBeforeNextDayMidnight(unixTimestamp: number) {
  // Convert the timestamp to a dayjs object in the browser's local time
  const timestamp = dayjs.unix(unixTimestamp);
  const today = dayjs().startOf('day'); // Start of the current day in local time
  const nextDayMidnight = today.add(1, 'day').endOf('day'); // End of the current day in local time

  // Check if the timestamp is within the same day and before next day's midnight
  return timestamp.isSame(today, 'day') && timestamp.isBefore(nextDayMidnight);
}

export function getRemainingMonths(subCreated: number, duration = 6) {
  // Convert the subscription creation timestamp to a Date object
  const createdDate = new Date(subCreated * 1000); // Convert seconds to milliseconds

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in months between the two dates
  const monthsElapsed = currentDate.getFullYear() * 12 + currentDate.getMonth()
    - (createdDate.getFullYear() * 12 + createdDate.getMonth());

  // Check if within 6 months
  if (monthsElapsed >= 0 && monthsElapsed <= duration) {
    const remainingMonths = duration - monthsElapsed;
    return remainingMonths;
  }
  return 0; // No remaining months within 6 months window
}

export function calculateDiscountedCost(planCost: number, sub: Subscription, couponIds = ['WELCOME10', 'WELCOME20']) {
  const coupon = sub?.billing_details?.coupons?.find((c: Coupon) => couponIds.includes(c.id));
  if (!coupon) {
    return planCost; // Return original cost if no matching coupon found
  }
  const remaining = getRemainingMonths(coupon?.created || 0, coupon?.duration_in_months || 6);

  if (
    !sub?.billing_details?.coupons
    || remaining <= 0
  ) {
    return planCost; // Return original cost if no valid coupons or months left
  }

  const discount = coupon?.percent_off || 0;
  return Math.ceil((planCost || 0) * (1 - discount / 100)) || planCost;
}

export function capitalize(str: string): string {
  if (!str) return ''; // Handle empty or null strings
  return str.charAt(0).toUpperCase() + str.slice(1);
}
