export interface Health {
  cpe: Array<Cpe>;
  health_indicator: HealthIndicator;
  ntd: Ntd;
  speed: Speed;
  dropouts: Dropouts;
  requested_from_ip: string;
  current_condition: CurrentCondition;
}

export interface CurrentCondition {
  alertMessage: string;
  code: string;
  status: string;
  summary: string;
}

export interface HealthIndicator {
  connectivity: StatusColor;
  performance: StatusColor;
  stability: StatusColor;
}

export interface Cpe {
  mac: string;
  vendor: string;
}

export interface Speed {
  status: string;
  description: string;
}

export interface Ntd {
  status: StatusColor;
  config: string;
  config_status: StatusColor;
  port: string
  port_state: 'Up' | 'Down'
  port_state_status: StatusColor
  port_speed: string
}

export interface Dropouts {
  unexpected: number;
  total: number;
  initiated: number;
  last_dropout: string;
}

export type StatusColor = 'Red' | 'Amber' | 'Green' | 'Grey'

export const statusToBadge = (status: StatusColor): 'warning' | 'error' |'success' | 'info' => {
  switch (status) {
    case 'Red':
      return 'error';
    case 'Green':
      return 'success';
    case 'Amber':
      return 'warning';
    default:
      return 'info';
  }
};

export const statusToColour = (status: StatusColor): 'red' | 'green' |'yellow' | 'blue' | 'gray' => {
  switch (status) {
    case 'Red':
      return 'red';
    case 'Green':
      return 'green';
    case 'Amber':
      return 'yellow';
    default:
      return 'gray';
  }
};
