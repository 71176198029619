import React from 'react';
import { HealthIndicator, StatusColor } from '../../models/health';
import { HealthStatusAlert } from './HealthStatusAlert';

export interface HealthIndicatorTagProps {
  indicator: HealthIndicator
  name: string
}

const indicatorToIntent = (indictor: StatusColor): 'warning' | 'error' | 'info' | 'success' => {
  switch (indictor) {
    case 'Red':
      return 'error';
    case 'Green':
      return 'success';
    case 'Amber':
      return 'warning';
    default:
      return 'info';
  }
};

export const HealthIndicatorTag: React.FC<HealthIndicatorTagProps> = ({ indicator, name }) => {
  if (!indicator) {
    return null;
  }

  const color = (indicator as any)[name] as StatusColor;

  return (

    <HealthStatusAlert
      status={indicatorToIntent(color)}
      description=""
      title={`${name || 'loading... '}`}
      closable={false}
    />
  );
};
