import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Health } from './health';
import { VPN } from './vpn';

dayjs.extend(utc);
dayjs.extend(timezone);

// Set timezone to Melbourne
const melbourneTimeZone = 'Australia/Melbourne';

export interface Customer {
  id: string;
  first_name: string;
  email: string;
  referral_code: string;
  vpn_tunnels_limit: number;
}

/* eslint-disable-next-line */
export enum SubscriptionStatus {
  Active,
  Away,
  Paused,
  Cancelled,
  PaymentFailed,
  Pending
}

export const getBillingPeriodWords = (sub: Subscription): string => {
  const start = dayjs.unix(sub.billing_details.current_period_start).tz(melbourneTimeZone).format('YYYY-MM-DD');
  const end = dayjs.unix(sub.billing_details.current_period_end).tz(melbourneTimeZone).format('YYYY-MM-DD');
  return `Current period 
                  ${start}
                  -
                  ${end}`;
};

export const getSubscriptionStatusString = (sub: Subscription): string => {
  if (sub.status === SubscriptionStatus.Cancelled) {
    return `${SubscriptionStatus[sub.status]} - ${dayjs.unix(sub.billing_details.canceled_at).tz(melbourneTimeZone).format('YYYY-MM-DD HH:mm:ss a')}`;
  }
  if (sub.status === SubscriptionStatus.Pending && sub.billing_details.billing_cycle_anchor > sub.billing_details.current_period_start) {
    return `${SubscriptionStatus[sub.status]} - ${dayjs.unix(sub.billing_details.billing_cycle_anchor).tz(melbourneTimeZone).format('YYYY-MM-DD HH:mm:ss a')}`;
  }

  if (sub.status === SubscriptionStatus.Active && sub.billing_details.is_in_trial) {
    return `${SubscriptionStatus[sub.status]} - trial ends ${dayjs.unix(sub.billing_details.trial_ends_at).tz(melbourneTimeZone).format('YYYY-MM-DD HH:mm:ss a')}`;
  }

  return SubscriptionStatus[sub?.status];
};

export interface Coupon {
  amount_off: number
  deleted: boolean
  duration: 'repeating'
  created: number
  duration_in_months: number
  id: string
  max_redemptions: number
  name: string
  percent_off: number
  valid: boolean

}

export interface Subscription {
  billing_details: {
    is_in_trial: boolean;
    next_invoice: number;
    monthly_charge: number;
    discounts: Array<number>;
    trial_ends_at: number;
    canceled_at: number;
    current_period_end: number;
    current_period_start: number;
    billing_cycle_anchor: number;
    coupons: Array<Coupon>;
  };
  id: string;
  created_at: number;
  updated_at: number;
  current_period_end: number;
  plan: Plan;
  loc_id: string;
  avc_id: string;
  payment_method: PaymentMethod;
  nbn_details: NbnDetails;
  ip: string;
  ip6: string;
  ip6_delegated: string
  poi: string;
  infrastructure_id: string;
  infrastructure_port: string;
  infrastructure_product_id: string;
  technology_type: string;
  traffic_class: string;
  dns_neptune_groups: number[];
  dns_allowed_regex_domains: string[];
  dns_allowed_exact_domains: string[];

  dns_blocked_regex_domains: string[];
  dns_blocked_exact_domains: string[];

  health: Health;

  status: SubscriptionStatus;
}

export interface NbnAddressDetail {
  id: string;
  latitude: number;
  longitude: number;
  techType: string;
  formattedAddress: string;
}

export interface NbnDetails {
  addressDetail: NbnAddressDetail;
}

export interface PaymentMethod {
  id: string;
  card_last_digits: string;
  card_type: string;
}

export interface Plan {
  id: string;
  aggregator_plan_id: string;
  name: string;
  cost: number;
  stripe_payment_link: string;
  stripe_product_id: string;
  up_mbps: number;
  down_mbps: number;
  description: string;
}

export interface CustomerObject {
  customer: Customer;
  subscriptions: { [key: string]: Subscription };
  vpns: { [key: string]: VPN}
}
