import {
  Box,
  Text,
  Heading,
  Stack,
  Divider,
  Container,
  Spinner,
  Button,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router';
import NavBar from '../NavBar';

import twoClayHemispheres from '../../images/two-clay-hemispheres.png';
import { Footer } from '../Footer';
import { useGetCustomerQuery } from '../../services/eventsService';

export interface DashboardTemplateProps {
  children: ReactNode;
}

export const DashboardTemplate: React.FC<DashboardTemplateProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [token, setToken] = useState('');
  const [avatarLink, setAvatarLink] = useState('');
  const {
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  const location = useLocation();

  useEffect(() => {
    async function getClaims() {
      const claims = await getIdTokenClaims();

      if (isAuthenticated) {
        const idToken = await getAccessTokenSilently();
        setToken(idToken!);
      }

      if (claims?.email !== '' && claims?.email !== undefined) {
        setEmail(claims?.email);
      }

      if (claims?.first_name !== '' && claims?.first_name !== undefined) {
        setFirstName(claims?.first_name);
      }
      if (claims?.picture !== '' && claims?.picture !== undefined) {
        setAvatarLink(claims?.picture);
      }
    }
    getClaims();
  }, [isAuthenticated, isLoading]);

  const {
    data: customer, error: customerError, isLoading: isCustomerLoading,
  } = useGetCustomerQuery('', {
    skip: !token,
  });

  return (
    <Box bgColor="deepSpace.900" height="100vh" width="100%" alignContent="center" justifyContent="center">
      <NavBar email={email} avatarLink={avatarLink} />

      <Box
        px={['0.5rem', '1rem', '1.5rem', '2rem']}
        style={{
          backgroundImage: `url("${twoClayHemispheres}"), linear-gradient(to right bottom, #001A1A, #000F0F)`,
        }}
        sx={{
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
        color="white"
      >

        <Stack spacing={4} width="100%" direction="column">
          <Stack
            p={5}
            alignItems="center"
            justifyContent={{
              base: 'flex-start',
              md: 'space-around',
            }}
            direction={{
              base: 'column',
              md: 'row',
            }}
          >
            <Stack
              textAlign="center"
              mb="2"
            >
              <Heading size="lg">
                Account Overview
                {' '}
                <Text color="purple.400">{email}</Text>
              </Heading>
            </Stack>
            {/*   <Stack mb="2" justifyContent="center" textAlign="center">
              {isCustomerLoading ? <Spinner /> : (
                <Heading color="teal.100" size="sm">
                  <ReferralCodeCopy code={customer?.customer?.referral_code} />
                </Heading>
              )}
            </Stack> */}

            <Stack justifyContent="center" textAlign="center">
              {isCustomerLoading ? <Spinner /> : (
                <Heading color="teal.100" size="sm">
                  {!location.pathname.includes('vpn') ? (
                    <Button onClick={() => navigate('/vpn')}>
                      Neptune VPN Service
                    </Button>
                  ) : (
                    <Button backgroundColor="grey" onClick={() => navigate('/')}>
                      Back to dashboard
                    </Button>
                  )}
                </Heading>
              )}
            </Stack>
          </Stack>

          <Divider />
        </Stack>
        {children}
        <Container>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};
