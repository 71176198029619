import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';
import { capitalize } from '../utils';

export interface PortResetModalProps {
  setShowResetModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  handleReset: () => void;
  testName: string;
}

export const PortResetModal: React.FC<PortResetModalProps> = ({
  handleReset, showModal, setShowResetModal, testName,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirm = () => {
    if (isChecked) {
      // Handle the confirmation logic here
      setShowResetModal(false);
      handleReset();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowResetModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {capitalize(testName.replaceAll('-', ' '))}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={2}>
            This diagnostic test will perform
            {' '}
            {testName.replaceAll('-', ' ')}
            .
          </Text>
          <Text mb={4}>
            In practical terms, this means your NBN connection will be reset.
            Your CPE will need to request a new DHCP lease. There will be a
            service interruption during the reset operation, and in some cases, you
            may need to reboot your Router (CPE).
          </Text>
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            mr={4}
            mt={1}
          />
          I understand that my connection will be interrupted for 1–3 minutes and that I may need to restart my router (CPE).
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor="black"
            color="white"
            onClick={handleConfirm}
            isDisabled={!isChecked}
            _disabled={{
              backgroundColor: 'black', // Set custom background color for disabled
              color: 'gray.300', // Set custom text color for disabled
              cursor: 'not-allowed', // Optional: Adjust cursor style
            }}
            _hover={{
              backgroundColor: 'gray.700', // Custom hover background color
              color: 'white', // Optional: Set text color on hover
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>

    </Modal>
  );
};
