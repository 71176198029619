import {
  Box,
  Button,
  Stack,
  createStandaloneToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import 'dayjs/locale/en-au';
import { useParams } from 'react-router';
import {
  FiPlusCircle,
} from 'react-icons/fi';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  fetchCustomerObject,
  getIP,
} from '../features/customer/customerObjectSlice';
import { VPNServiceCard } from './ServiceCards';
import { DashboardTemplate } from './page_templates';
import { Help } from './Help';
import {
  useCreateVPNMutation, useGetCustomerQuery,
} from '../services/eventsService';
import { RootState } from '../app/store';
import { VPN } from '../models/vpn';

dayjs.locale('en-au');

const VPNHelp = 'VPN allows you to maintain privacy. You can generate up to two VPN services in your account for free.';

const toaster = createStandaloneToast();

const VPNList = () => {
  const dispatch = useAppDispatch();
  const [showVPNHelp, setShowVPNHelp] = useState(false);
  const { subId } = useParams();

  const token = useAppSelector((state: RootState) => state.auth.token);
  const ip = useAppSelector((state: RootState) => state.customer.ip);

  const {
    data: customer, error: customerError, isLoading: isCustomerLoading,
  } = useGetCustomerQuery('', {
    skip: !token,
  });

  const [newlyCreateVPN, setNewlyCreateVPN] = useState<VPN | undefined>(undefined);

  const [createVPN, { isLoading: createVpnIsLoading, isSuccess: createVpnIsSuccess, error: createVpnIsError }] = useCreateVPNMutation();

  const [focused, setFocused] = useState('');

  useEffect(() => {
    dispatch(fetchCustomerObject(token));
  }, []);

  useEffect(() => {
    if (customer && customer?.subscriptions) {
      const s = customer?.subscriptions[subId || ''];
      if (s) {
        dispatch(getIP({ token, subId: s.id }));
      }
    }
  }, [customer, isCustomerLoading, createVpnIsSuccess]);

  const showToaster = (toasterTitle: string, status: 'success' | 'error' | 'info' | 'warning') => {
    toaster.toast({
      title: toasterTitle,
      status,
      duration: 5000,
      position: 'top',
    });
  };

  return (
    <DashboardTemplate>
      <Box style={{
        marginRight: '2%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        gap: '20px',
      }}
      >

        {showVPNHelp && (
        <Help
          helpHeader="We detected your IP address successfully"
          helpText={VPNHelp}
          isOpen={showVPNHelp}
          onClose={() => setShowVPNHelp(false)}
        />
        )}

        <Button
          size="lg"
          onClick={async () => {
            await createVPN({ private_key: '', vpn_id: '', atmosphere_enabled: true }).unwrap()
              .then(() => showToaster('Successfully create VPN tunnel. Please click show QR code or donwload the wireguard configuration.', 'success'))
              .catch((err) => showToaster('The maximum limit of tunnels is reached. Please contact noc@neptune.net.au if you want to increase it.', 'info'));
          }}
          leftIcon={<FiPlusCircle />}
        >
          Add VPN Service

        </Button>
        Current VPN limt:
        {' '}
        {customer?.customer?.vpn_tunnels_limit && customer.customer.vpn_tunnels_limit > 0 ? customer.customer.vpn_tunnels_limit : 2}
      </Box>

      <Stack direction={['column', 'column', 'column', 'row']} alignItems="ceneter" justifyContent="center">
        {Object.keys(customer?.vpns || {}).map(((key) => {
          const sub = customer?.vpns[key] || ({} as VPN);
          return (
            <Stack
              p={3}
              py={3}
            >
              <VPNServiceCard
                qr_code={sub?.qr_code}
                config={sub?.config}
                email=""
                serviceId={sub.id}
                subTitle="VPN"
                sub={sub}
                title="Virtual Location"
                currentDiscount={0.7}
                monthlyCost={0}
                upSpeed={0}
                downSpeed={0}
                setFocused={(foo) => setFocused(foo)}
                onPick={() => null}
                focused={focused}
              />
            </Stack>
          );
        }))}
      </Stack>

    </DashboardTemplate>
  );
};

export default VPNList;
