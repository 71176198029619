import {
  Box,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  SlideFade,
  HStack,
  Text,
  Card,
  createStandaloneToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import React, {
  useState, useCallback, useEffect, SetStateAction, Dispatch,
} from 'react';

import ringsClayUrl from '../../images/rings-clay.png';
import { Plan } from '../../models/plans';
import { getConfig, IBackendConfig } from '../../config';
import { useUdpateSubscriptionMutation } from '../../services/eventsService';
import { SubscriptionChangePlanModal } from '../ChangePlanModal';
import { Subscription } from '../../models/customer';
import { calculateDiscountedCost } from '../../utils';

const cfg: IBackendConfig = getConfig();

const toast = createStandaloneToast();

const showToaster = (toasterTitle: string, status: 'success' | 'error' | 'info' | 'warning') => {
  toast.toast({
    title: toasterTitle,
    status,
    duration: 5000,
    position: 'top',
  });
};

const cardStyles = (numCards: number, spaceConstrained: boolean) => {
  if (numCards > 3 && spaceConstrained) {
    return {
      gap: 4,
      focusedFlexBasis: '100%',
      unfocusedFlexBasis: '100%',
      cardSize: 'sm',
      focusedHeadingSize: 'lg',
      unfocusedHeadingSize: 'md',
      textSize: 'sm',
      textLines: 10,
      minHeight: ['150px', '150px'],
    };
  }

  return {
    gap: 6,
    focusedFlexBasis: '110%',
    unfocusedFlexBasis: '100%',
    cardSize: 'sm',
    focusedHeadingSize: 'sm',
    unfocusedHeadingSize: 'sm',
    textSize: 'md',
    textLines: 2,
  };
};

export interface PlanPickerProps {
  sub: Subscription
  subId: string;
  locationId: string
  focusOn?: string;
  spaceConstrained?: boolean;
  buttonText: string;
  isBlocked: boolean;
  setBlocked: Dispatch<SetStateAction<boolean>>;
}

export const PlanPicker = ({
  focusOn = 'SLC-NBN-100-20', spaceConstrained = false, buttonText, subId, locationId, isBlocked, setBlocked, sub,
}: PlanPickerProps) => {
  const [focused, setFocused] = useState<string>(focusOn || 'SLC-NBN-100-20');
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState<Array<Plan>>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>(undefined);
  const [showModal, setShowChangePlanModal] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);

      const body = await JSON.stringify({
        locationId,
        sourceType: 'nbn',
      });
      const plansFromResp = await fetch(
        '/api/v1/qualify-by-loc-id',
        {
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const jsonPlans = await plansFromResp.json();
      if (!jsonPlans?.plans) {
        const allPlans = await fetch('/api/v1/plans');
        const plansToSet = await allPlans.json();
        setPlans(plansToSet);
      } else {
        setPlans(jsonPlans.plans.filter((plan: Plan) => plan.aggregator_plan_id !== sub.plan.aggregator_plan_id));
      }
      setLoading(false);
    };
    fetchPlans();
  }, []);

  const [udpateSub, {
    isLoading,
    isSuccess, error,
  }] = useUdpateSubscriptionMutation();

  const isFocused = useCallback((id: string) => focused === id, [focused]);

  const handleSubmit = (planID: string) => {
    setLoading(true);
    showToaster('Updating selected plan', 'info');
    udpateSub({ plan_id: planID, subId })
      .unwrap()
      .then(() => {
        showToaster('Plan has been successfully updated. Your service may experience a brief disruption while NBN® updates the connection.', 'success');
        setBlocked(true);
      }).catch((err) => showToaster('An error occured while updating the plan', 'error'))
      .finally(() => setLoading(false));
  };

  const cardStyle = cardStyles(plans.length, !!spaceConstrained);

  return (

    <Flex direction={['column', 'column', 'column', 'column']} gap={cardStyle.gap}>
      {plans.map((plan) => (
        <Box
          sx={{
            _hover: {
              cursor: 'pointer',
            },
          }}
          flexBasis={isFocused(plan.neptune_id) ? cardStyle.focusedFlexBasis : cardStyle.unfocusedFlexBasis}
          transition="flex-basis 0.2s ease"
          onMouseEnter={() => setFocused(plan.neptune_id)}
          onClick={() => setSelectedPlan(plan)}
          display="flex"
          flexDirection="column"
          key={plan.neptune_id}
        >
          <Card
            minHeight={cardStyle.minHeight}
            flex="1"
            size={cardStyle.cardSize}
            variant={isFocused(plan.neptune_id) ? 'blue' : 'deepSpace'}
          >
            <Box
              display="flex"
              flexDirection="column"
              flex="1"
              bgPosition={isFocused(plan.neptune_id) ? 'bottom -100px right -100px' : 'top -150px right -150px'}
              bgSize="500px"
              bgRepeat="no-repeat"
              bgImage={ringsClayUrl}
            >
              <CardHeader>
                <Heading
                  size={isFocused(plan.neptune_id) ? cardStyle.focusedHeadingSize : cardStyle.unfocusedHeadingSize}
                  as="h3"
                  mb="3"
                  dangerouslySetInnerHTML={{ __html: plan.name }}
                />
                <SlideFade
                  transition={{ enter: { duration: 0.3 }, exit: { duration: 0.3 } }}
                  in={isFocused(plan.neptune_id)}
                >
                  <Text noOfLines={cardStyle.textLines} fontSize={cardStyle.textSize}>{ plan.description }</Text>
                </SlideFade>
              </CardHeader>
              <CardBody display="flex" alignItems="flex-end">
                <Box>
                  <Heading fontWeight="500" as="p" size="lg">
                    $
                    {calculateDiscountedCost(plan?.cost, sub)}
                  </Heading>
                  <Text color="deepSpace.100" variant="mini">Per month</Text>
                  {/* We do not allow discounts to carry, might change in future */}
                  {/* <DiscountInfo plan={plan} sub={sub} isFocused={isFocused} /> */}
                  <HStack>
                    <Text
                      fontFamily="'Belfast Grotesk', sans-serif"
                      fontSize="2xl"
                      casing="uppercase"
                    >
                      { plan.down_mbps }
                      {' '}
                      <Box as="span" display="inline" mr="1rem" fontSize="xs">MBPS</Box>
                      <Box as="span" display="block" color="deepSpace.100" fontSize="2xs">Download</Box>
                    </Text>
                    <Text
                      fontFamily="'Belfast Grotesk', sans-serif"
                      fontSize="2xl"
                      casing="uppercase"
                    >
                      { plan.up_mbps }
                      {' '}
                      <Box as="span" display="inline" fontSize="xs">MBPS</Box>
                      <Box as="span" display="block" color="deepSpace.100" fontSize="2xs">Upload</Box>
                    </Text>
                  </HStack>
                </Box>
              </CardBody>

              <SubscriptionChangePlanModal
                sub={sub}
                setShowChangePlanModal={setShowChangePlanModal}
                showModal={showModal}
                selectedPlan={
                  plans?.find(((p) => p.neptune_id === selectedPlan?.neptune_id)) || {} as Plan
                }
                handleChangePlan={() => {
                  handleSubmit(selectedPlan?.neptune_id || focused);
                }}
              />
              <CardFooter>
                <input type="hidden" name="planID" value={plan.neptune_id} />
                <Popover trigger={isBlocked ? 'hover' : undefined} placement="top">
                  <PopoverTrigger>
                    {/* Wrap the button in a Box to enable popover on hover */}
                    <Box>
                      <Button
                        type="submit"
                        name="_action"
                        isLoading={loading}
                        isDisabled={loading || isBlocked}
                        onClick={() => {
                          setFocused(plan.neptune_id);
                          setSelectedPlan(plan);
                          setShowChangePlanModal(true);
                        }}
                        width={['70%', '150px']}
                        size="md"
                        variant={isFocused(plan.neptune_id) ? 'cyan' : 'white'}
                      >
                        { buttonText }
                      </Button>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverBody style={{ color: 'black' }}>
                      You can only change plans once per day. Please wait until midnight.
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </CardFooter>
            </Box>
          </Card>
        </Box>
      ))}
    </Flex>
  );
};
