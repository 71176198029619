import React, {
  Dispatch, SetStateAction, useState,
} from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Link,
  Text,
  createStandaloneToast,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import DropdownWithTagsAndCustomOptions from './DropdownWithTags';

export interface SubscriptionCancelModalProps {
  setShowAwayModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  handleCancel: () => void;
  trialing: boolean;
}
type IntercomMessage = {
  from: {
    type: string; // e.g., "user"
    email: string;
  };
  body: string; // The message content
};

const toast = createStandaloneToast();

export const SubscriptionCancelModal: React.FC<SubscriptionCancelModalProps> = ({
  handleCancel, showModal, setShowAwayModal, trialing,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const showToaster = (toasterTitle: string, status: 'success' | 'error' | 'info' | 'warning') => {
    toast.toast({
      title: toasterTitle,
      status,
      duration: 5000,
      position: 'top',
    });
  };

  const {
    getIdTokenClaims, getAccessTokenSilently,
  } = useAuth0();

  const handleConfirm = async () => {
    if (isChecked) {
      try {
        const idToken = await getAccessTokenSilently();

        const claims = await getIdTokenClaims();

        await fetch('/api/v1/customer/intercom/message', {
          body: JSON.stringify({
            body: `Automatic cancellation reason: ${selectedOptions.join('. ')}`,
            from: {
              type: 'user',
              email: claims?.email,
            },
          }),
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            ContentType: 'application/json',
          },
        });
        showToaster('Your cancellation reason has been successfully sent.', 'success');
        setShowAwayModal(false);
      } catch (err) {
        console.log('error', err);
      }

      // Handle the confirmation logic here
      setShowAwayModal(false);

      handleCancel();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowAwayModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Subscription</ModalHeader>
        <ModalCloseButton />
        {!trialing ? (
          <>
            {' '}
            <ModalBody>

              <DropdownWithTagsAndCustomOptions options={['Speed is not as advertised', 'Dropouts', 'Too expensive', 'Change of mind', 'Other']} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} />

            </ModalBody>
            <ModalBody>
              <Text mb={4}>
                <p>You are about to disconnect your service.</p>

                <p>This process may take a few hours but is usually completed within minutes. Once disconnected, your service will be fully deactivated. Please note the following:</p>

                <b>Data Retention</b>
                : In compliance with data retention obligations, we are required to retain certain information, including your IP address that was assigned to you while you were subscribed to Nepune Internet and your full name, for a period of 2 years.

                We are sorry to see you go. Please confirm below to proceed with the disconnection.
              </Text>
              <Checkbox
                isChecked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              >
                I accept that
                {' '}
                <b>the service will be fully disconnected.</b>
              </Checkbox>
            </ModalBody>

            <ModalFooter>
              <Button disabled={trialing} backgroundColor="blue.300" color="white" onClick={handleConfirm} isDisabled={!isChecked || selectedOptions.length === 0}>
                Confirm
              </Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody>
              Your subscription is still in the trial period. To better understand your concern, please select your reason and send us a message, or send an email to
              {' '}
              <Link isExternal href="mailto:support@neptune.net.au">support@neptune.net.au</Link>
              .
              <br />
            </ModalBody>
            <ModalBody>
              <DropdownWithTagsAndCustomOptions options={['Speed is not as advertised', 'Dropouts', 'Too expensive', 'Change of mind', 'Other']} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} />
            </ModalBody>

            <ModalFooter>
              <Button
                backgroundColor="blue.300"
                color="white"
                onClick={async () => {
                  try {
                    const idToken = await getAccessTokenSilently();
                    const claims = await getIdTokenClaims();

                    await fetch('/api/v1/customer/intercom/message', {
                      body: JSON.stringify({
                        body: `Cancellation request: ${selectedOptions.join('. ')}`,
                        from: {
                          type: 'user',
                          email: claims?.email,
                        },
                      }),
                      method: 'POST',
                      headers: {
                        Authorization: `Bearer ${idToken}`,
                        ContentType: 'application/json',
                      },
                    });
                    showToaster('Your cancellation request has been successfully sent.', 'success');
                    setShowAwayModal(false);
                  } catch (err) {
                    console.log('error', err);
                    showToaster('An error occurred while trying to send a message. Please contact support@neptune.net.au.', 'error');
                  }
                }}
              >
                OK
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
