import React from 'react';
import {
  Cpe,
} from '../../models/health';
import { HealthStatusAlert } from './HealthStatusAlert';

export interface CpeTagsProps {
  cpe: Cpe
  idx?: number
}

export const CpeTags: React.FC<CpeTagsProps> = ({ cpe, idx = 0 }) => {
  if (!cpe) {
    return null;
  }

  return (
    <HealthStatusAlert
      status="info"
      description=""
      title={`${idx > 0 ? `CPE #${idx}` : null} MAC: ${cpe?.mac || 'unknown'} - vendor ${cpe?.vendor || 'unknown'}`}
      closable={false}
    />
  );
};
