import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Link,
} from '@chakra-ui/react';
import { Plan } from '../models/plans';
import { Subscription } from '../models/customer';
import DiscountInfo from './DiscountInfo';
import { calculateDiscountedCost } from '../utils';

export interface SubscriptionChangePlanModalProps {
  setShowChangePlanModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  selectedPlan: Plan;
  sub: Subscription;
  handleChangePlan: () => void;
}

export const SubscriptionChangePlanModal: React.FC<SubscriptionChangePlanModalProps> = ({
  handleChangePlan, sub, showModal, setShowChangePlanModal, selectedPlan,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirm = () => {
    if (isChecked) {
      // Handle the confirmation logic here
      setShowChangePlanModal(false);
      handleChangePlan();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowChangePlanModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Change Subscription Plan
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            You are about to change your plan to
            {' '}
            <b>{selectedPlan.name}</b>
            .
          </Text>
          <Text mb={4}>
            This change can only be made
            {' '}
            <b>once per day.</b>
          </Text>
          <Text mb={4}>
            Any remaining charges prepaid for your current plan will either be applied toward the new plan if it costs more, or carried forward to reduce your bill over the following billing periods.
          </Text>
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            mr={4}
            mt={1}
          />

          I accept the monthly charge of
          {' '}
          <b>
            $
            {calculateDiscountedCost(selectedPlan.cost, sub)}
          </b>
          {' '}
          (including GST),
          {' '}
          {' '}
          <Link
            href="https://www.neptune.net.au/critical-information-summary"
            isExternal
            rel="noopener noreferrer"
            target="_blank"
            color="black"
          >
            Critical Information Summary
          </Link>
          {' '}
          and
          {' '}
          <Link
            href="https://www.neptune.net.au/terms"
            isExternal
            rel="noopener noreferrer"
            target="_blank"
            color="black"
          >
            The Terms of Service.
          </Link>
          <DiscountInfo isModal sub={sub} plan={selectedPlan} isFocused={() => true} />
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor="black"
            color="white"
            onClick={handleConfirm}
            isDisabled={!isChecked}
            _disabled={{
              backgroundColor: 'black', // Set custom background color for disabled
              color: 'gray.300', // Set custom text color for disabled
              cursor: 'not-allowed', // Optional: Adjust cursor style
            }}
            _hover={{
              backgroundColor: 'gray.700', // Custom hover background color
              color: 'white', // Optional: Set text color on hover
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>

    </Modal>
  );
};
