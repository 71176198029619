import {
  Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton, useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

export interface HealthStatusAlertProps {
  status: 'error' | 'success' | 'warning' | 'info'
  title: string
  description: string
  closable?: boolean
  onCloseAlert?: () => void
}

export const HealthStatusAlert: React.FC<HealthStatusAlertProps> = ({
  status, title, description, closable = true, onCloseAlert = () => null,
}) => {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true });
  if (!title) {
    return null;
  }

  return (isVisible
    ? (
      <Alert
        color="black"
        status={status}
        mb={2}
      >
        <AlertIcon />
        <Box>
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription>{description}</AlertDescription>

        </Box>
        {closable && (
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={() => {
            onCloseAlert();
            onClose();
          }}
        />
        )}

      </Alert>
    ) : null);
};
