import React from 'react';
import { Ntd, StatusColor } from '../../models/health';
import { HealthStatusAlert } from './HealthStatusAlert';

export interface NtdTagsProps {
  ntd: Ntd
}

const indicatorToIntent = (indictor: StatusColor): 'warning' | 'error' | 'info' | 'success' => {
  switch (indictor) {
    case 'Red':
      return 'error';
    case 'Green':
      return 'success';
    case 'Amber':
      return 'warning';
    default:
      return 'info';
  }
};

export const NtdTags: React.FC<NtdTagsProps> = ({ ntd }) => {
  if (!ntd) {
    return null;
  }

  return (
    <>
      {!!ntd?.status && (
      <HealthStatusAlert
        status={indicatorToIntent(ntd?.status)}
        description=""
        title="NTD"
        closable={false}
      />
      )}

      {!!ntd?.port && (
      <HealthStatusAlert
        status={ntd?.port_state === 'Up' ? 'success' : 'error'}
        description=""
        title={`Port ${ntd.port} is ${ntd.port_state}`}
        closable={false}
      />
      )}

    </>
  );
};
