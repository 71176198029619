import {
  Box,
  Button,
  Center,
  Spinner,
  Divider,
  Collapse,
  createStandaloneToast,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import 'dayjs/locale/en-au';
import { useNavigate, useParams } from 'react-router';
import { ViewOffIcon } from '@chakra-ui/icons';

import dayjs from 'dayjs';
import { FiMail, FiTool } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  fetchCustomerObject,
  getHealthStatus,
  getIP,
} from '../features/customer/customerObjectSlice';
import { Subscription } from '../models/customer';
import { DashboardTemplate } from './page_templates';
import { capitalize, isNeptuneIp } from '../utils';
import { Help } from './Help';
import { Health, statusToBadge } from '../models/health';
import {
  useGetAvailableTestsQuery, useGetCustomerQuery, useGetEventsQuery,
} from '../services/eventsService';
import { RootState } from '../app/store';
import { EventType, eventTypeToString } from '../models/event';
import { DropoutsTags, HealthStatusAlert, NtdTags } from './HealthComponents';
import { HealthIndicatorTag } from './HealthComponents/HealthIndicatorTag';
import { CpeTags } from './HealthComponents/CpeTags';
import { PortResetModal } from './ResetPortModal';

dayjs.locale('en-au');

const IPv4Help = 'We have detected that your current IP address belongs to our network, which likely indicates that your internet connection is working properly. This means your device is successfully connected to our system, and everything appears to be functioning as expected.';

interface HealthReportElementProps {
  health: Health;
}

const toast = createStandaloneToast();

const showToaster = (toasterTitle: string, status: 'success' | 'error' | 'info' | 'warning') => {
  toast.toast({
    title: toasterTitle,
    status,
    duration: 5000,
    position: 'top',
  });
};

const HealthStatus = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showIPHelp, setShowIPHelp] = useState(false);
  const { subId } = useParams();
  const [subIP, setSetSubIP] = useState<undefined| string>(undefined);
  const [subIP6, setSetSubIP6] = useState<undefined| string>(undefined);
  const [subIP6Delegated, setSetSubIP6Delegated] = useState<undefined| string>(undefined);
  const [testIsRunning, setTestIsRunning] = useState(false);
  const [showPortResetModal, setShowPortResetModal] = useState(false);
  const [portResetTestName, setPortResetTestName] = useState('ntd-reset');

  const token = useAppSelector((state: RootState) => state.auth.token);
  const ip = useAppSelector((state: RootState) => state.customer.ip);

  const [subscription, setSubscription] = useState<Subscription>(
    {} as Subscription,
  );

  const {
    data, error, isLoading: isEventsLoading, refetch,
  } = useGetEventsQuery(subId || '', {
    pollingInterval: 3000,
    skip: !token,
  });

  const {
    data: customer, error: customerError, isLoading: isCustomerLoading,
  } = useGetCustomerQuery('', {
    skip: !token,
  });

  const {
    data: tests, error: testsError, isLoading: isTestsLoading,
  } = useGetAvailableTestsQuery(subId || '', {
    skip: !token,
  });

  useEffect(() => {
    dispatch(fetchCustomerObject(token));
  }, []);

  useEffect(() => {
    if (customer && customer?.subscriptions) {
      const s = customer?.subscriptions[subId || ''];
      if (s) {
        setSubscription(s);
        setSetSubIP(s.ip);
        setSetSubIP6(s.ip6);
        setSetSubIP6Delegated(s.ip6_delegated);
        dispatch(getIP({ token, subId: s.id }));
      }
    }
  }, [customer, isCustomerLoading]);

  const [showEvents, setShowEvents] = React.useState(false);

  const handleToggle = () => setShowEvents(!showEvents);

  let currentStatus: Health = {} as Health;
  try {
    currentStatus = JSON.parse([...data?.events || []].sort((a, b) => dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix())
      .find((event) => event.event_type === EventType.HealthCheckComplete)?.payload || '{}');
  } catch (err) {
    console.log('err', err);
  }

  const handleClick = async (test: string) => {
    try {
      setTestIsRunning(true);
      const result = await fetch(`/api/v1/customer/subscription/${encodeURIComponent(subId || '')}/runTest/${test}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result?.status === 429) {
        showToaster('A diagnostic operation is in progress, please try again later', 'warning');
      } else if (result?.status === 500) {
        showToaster('Error scheduling a test, please contact support@neptune.net.au', 'error');
      } else {
        showToaster(`${capitalize(test.replaceAll('-', ' '))} has been successfully scheduled, please check in a few minutes for results.`, 'success');
        setShowEvents(true);
      }
    } catch (err) {
      showToaster('Error scheduling a test, please contact support@neptune.net.au', 'error');
    } finally {
      setTestIsRunning(false);
    }
  };

  const getToolTipText = (test: string) => {
    if (test === 'loopback') {
      return 'This test confirms that NTD device or a VDSL line can send and receive signals correctly.';
    }
    if (test.includes('reset')) {
      return `Perform ${test.replaceAll('-', ' ')}.`;
    }

    if (test.includes('status')) {
      return `Check ${test.replaceAll('-', ' ')}.`;
    }

    return test.replaceAll('-', ' ');
  };

  return (
    <DashboardTemplate>
      {showIPHelp && (
        <Help
          helpHeader="We detected your IP address successfully"
          helpText={IPv4Help}
          isOpen={showIPHelp}
          onClose={() => setShowIPHelp(false)}
        />
      )}

      {error && (
        <Box
          marginX="5%"
          mb={2}
          mt={2}
        >
          <HealthStatusAlert
            status="error"
            title="Failed to fetch health status"
            description={'data' in error ? error.data as string : 'unknown error'}
          />
        </Box>
      )}
      <Box
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        mt="10px"
        display="flex"
        justifyContent="center"
        gap="10px"
      >

        <Box position="relative" display="inline-block">
          <Button
            size="sm"
            variant="solid"
            leftIcon={showEvents ? <ViewOffIcon /> : <FiMail />}
            onClick={handleToggle}
          >
            {showEvents ? 'Hide events' : 'Show events'}
          </Button>
          {data?.events && data?.events?.length > 0 && (
          <Box
            position="absolute"
            top="-4px"
            right="-4px"
            bg="red.500"
            color="white"
            fontSize="xs"
            fontWeight="bold"
            borderRadius="full"
            width="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            boxShadow="md"
          >
            {data?.events?.length}
          </Box>
          )}
        </Box>

        <Button
          cursor="pointer"
          size="sm"
          isLoading={isCustomerLoading || isEventsLoading}
          isDisabled={!!customerError}
          leftIcon={<FiTool />}
          onClick={() => {
            dispatch(getHealthStatus({ token, subId: subId || '' }));
            setShowEvents(true);
          }}
        >
          Run Healthcheck
        </Button>
        <PortResetModal testName={portResetTestName} showModal={showPortResetModal} setShowResetModal={setShowPortResetModal} handleReset={() => handleClick(portResetTestName)} />
        {tests?.testTypes?.map((test) => (
          <Tooltip placement="bottom" label={getToolTipText(test)}>
            <Button
              size="sm"
              cursor="pointer"
              isLoading={isCustomerLoading || isEventsLoading || testIsRunning}
              isDisabled={!!customerError || testIsRunning}
              onClick={async () => {
                if (test.includes('reset')) {
                  setPortResetTestName(test);
                  setShowPortResetModal(true);
                } else {
                  handleClick(test);
                }
              }}
            >
              {capitalize(test.replaceAll('-', ' '))}
            </Button>

          </Tooltip>
        ))}

        <Button bgColor="gray" size="sm" isLoading={isCustomerLoading} onClick={() => navigate('/dashboard')}>
          Back to Dashboard
        </Button>
      </Box>

      <Box>

        <Collapse in={showEvents}>
          {isEventsLoading ? <Center><Spinner size="lg" /></Center>
            : [...data?.events || []].sort((a, b) => dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix()).map((event) => (
              <Box mt={2} marginX="5%">
                <HealthStatusAlert
                  closable
                  onCloseAlert={async () => {
                    try {
                      setTestIsRunning(true);
                      const result = await fetch(`/api/v1/customer/subscription/${encodeURIComponent(subId || '')}/events/${encodeURIComponent(event.id)}`, {
                        method: 'DELETE',
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      });
                      if (result?.status === 200) {
                        showToaster('Successfully marked as read', 'success');
                      } else {
                        showToaster('Failed to mark event as read, try again later.', 'error');
                        setShowEvents(true);
                      }
                    } catch (err) {
                      showToaster('Failed to mark event as read, try again later.', 'error');
                    } finally {
                      setTestIsRunning(false);
                    }
                  }}
                  status={
              eventTypeToString(event.event_type)[1]
            }
                  description={event?.payload?.includes('cpe') || !event?.payload ? eventTypeToString(event.event_type)[0] : event.payload}
                  title={`${dayjs(event.timestamp).format('DD MMMM YYYY, h:mm A')} - ${eventTypeToString(event.event_type)[0]}`}
                />
              </Box>
            ))}
        </Collapse>

      </Box>

      <Box
        marginX="5%"
        mt={4}
        mb={2}
        flexDir="row"
        flexWrap="wrap"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
      >

        {data?.events && currentStatus
        && (
        <>

          <HealthStatusAlert
            status="info"
            description=""
            title={`Connection Address: ${subscription?.nbn_details?.addressDetail?.formattedAddress || 'loading...'}`}
            closable={false}
          />
          <HealthStatusAlert
            status="info"
            description=""
            title={`IP Address: ${subIP || 'loading...'}`}
            closable={false}
          />
          <HealthStatusAlert
            status="info"
            description=""
            title={`IPv6 Address: ${subIP6 || 'loading...'}`}
            closable={false}
          />
          <HealthStatusAlert
            status="info"
            description=""
            title={`IPv6 Delegated Prefix: ${subIP6Delegated || 'loading...'}`}
            closable={false}
          />
          {currentStatus?.cpe && Array.isArray(currentStatus?.cpe) ? (currentStatus?.cpe?.map((c, i) => <CpeTags cpe={c} idx={i + 1} />)) : null}
          <HealthIndicatorTag indicator={currentStatus?.health_indicator} name="performance" />
          <HealthIndicatorTag indicator={currentStatus?.health_indicator} name="connectivity" />
          <HealthIndicatorTag indicator={currentStatus?.health_indicator} name="stability" />
          <DropoutsTags health={currentStatus} />
          <NtdTags ntd={currentStatus?.ntd} />

        </>
        )}
      </Box>

      {!!data?.events && currentStatus
          && (
            <Box mt={4} mb={2} marginX="5%">
              <HealthStatusAlert
                status={
                  statusToBadge(currentStatus?.current_condition?.status as 'Red' | 'Green' | 'Amber')
                }
                description={currentStatus?.current_condition?.summary}
                title={currentStatus?.current_condition?.alertMessage}
              />
            </Box>
          )}

      <Box mb={2} marginX="5%">
        {isNeptuneIp(ip) ? (
          <HealthStatusAlert
            title="Your service is healthy"
            description="We have detected that you are connected using the IP address assigned to your service. This suggests that your service is most likely operating normally."
            status="success"
          />
        ) : (
          <HealthStatusAlert
            title="We detected that you have connected from outside of our network."
            description={`
               Your current IP address:
              ${ip}`}
            status="info"
          />
        )}
      </Box>
      <Divider mt={2} mb={4} />

    </DashboardTemplate>
  );
};

export default HealthStatus;
