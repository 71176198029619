import dayjs from 'dayjs';
import React from 'react';
import {
  Health,
} from '../../models/health';
import { HealthStatusAlert } from './HealthStatusAlert';

export interface DropoutsTagsProps {
  health: Health
}

export const DropoutsTags: React.FC<DropoutsTagsProps> = ({ health }) => {
  if (!health?.dropouts) {
    return null;
  }

  const lastDropout = () => {
    if (!health?.dropouts?.last_dropout) {
      return 'no dropouts';
    }

    const current = dayjs();
    const last = dayjs(health?.dropouts?.last_dropout);
    if ((current.unix() - last.unix()) >= 90 * 86400) {
      return 'no dropouts';
    }

    return dayjs(health?.dropouts?.last_dropout).format('DD MMMM YYYY, h:mm A');
  };

  return (
    <HealthStatusAlert
      status={health?.dropouts?.total > 5 ? 'error' : 'success'}
      description={`${lastDropout() === 'no dropouts' ? '' : `Last dropout date: ${lastDropout()}`}`}
      title={`Dropouts total: ${health?.dropouts?.total || 'no dropouts'}`}
      closable={false}
    />
  );
};
