import React, { Dispatch, SetStateAction } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Code,
} from '@chakra-ui/react';

export interface SubscriptionChangePlanModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
}

export const AtmosphereOnVPNModal: React.FC<SubscriptionChangePlanModalProps> = ({
  showModal, setShowModal,
}) => {
  const handleConfirm = () => {
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Atmosphere on VPN
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            Atmosphere on VPN is enabled by default with the recommended settings. We have not implemented category-based management yet.
          </Text>
          <Text mb={4}>
            If you want to disable Atmosphere, please replace the DNS list in your WireGuard app with a list of public DNS servers, such as in example below.
            Alternatively you can reset the keys and get a new config without Atmosphere DNS populated.
          </Text>
          <Code
            as="pre"
            p={3}
            bg="gray.800"
            color="gray.200"
            whiteSpace="pre-wrap"
            wordBreak="break-word"
          >
            DNS = 1.1.1.1,8.8.8.8,2606:4700:4700::1111
          </Code>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor="black"
            color="white"
            onClick={handleConfirm}
            _disabled={{
              backgroundColor: 'black', // Set custom background color for disabled
              color: 'gray.300', // Set custom text color for disabled
              cursor: 'not-allowed', // Optional: Adjust cursor style
            }}
            _hover={{
              backgroundColor: 'gray.700', // Custom hover background color
              color: 'white', // Optional: Set text color on hover
            }}
          >
            Hide
          </Button>
        </ModalFooter>
      </ModalContent>

    </Modal>
  );
};
